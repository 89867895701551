import Vue from 'vue'
// import ElementUI from 'element-ui';
import App from './App.vue'
import router from './router'
import './assets/css/style.css'
import {getRequest} from "@/api/api";
// import 'element-ui/lib/theme-chalk/index.css';

// Vue.use(ElementUI);
Vue.prototype.getRequest = getRequest;


Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
