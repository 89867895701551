// 引入 axios
import axios from "axios";



// let base = 'http://localhost:8067';
// let base = 'http://192.168.1.206:8067';
let base = 'http://8.148.21.110:8067';
// 
//传送json格式的get请求
export const getRequest=(url,params)=>{
    return axios({
        method:'get',
        url:`${base}${url}`,
        data: params,
    })
}
